<template>
  <div class="Statistics">
    <el-row>
      <el-col :span="24" class="toolbar scroll">
        <simple-form ref="simpleCURDAddForm" :model="searchForm" inline :show-form="argsSearchForm"
                     current-type="select"
        >
          <template #default>
            <el-form-item>
              <el-button :loading="$store.state.isLoading" type="primary" plain size="medium" @click="search">
                查询
              </el-button>
            </el-form-item>
          </template>
        </simple-form>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-tabs v-model="parentTab">
          <el-tab-pane v-if="showInspectCard" id="deleteByProjectType" label="查勘统计" name="first" />
          <el-tab-pane v-if="inspect.length>0" label="施工统计" name="second">
            <div v-for="(item) in constructShow" :key="item.name" :name="item.name" class="statistics">
              <div class="statistics_child">
                <el-descriptions class="margin-top" :column="2">
                  <el-descriptions-item :label="item.label" class="label" />
                </el-descriptions>

                <el-descriptions class="margin-top" :column="2">
                  <el-descriptions-item v-for="(show) in item.shows" :key="show.name" class="label" :label="show.name">
                    {{ show.param }}
                  </el-descriptions-item>
                </el-descriptions>

                <div class="show">
                  <div class="label">
                    安装进度：
                  </div>
                  <br>
                  <div class="row">
                    <el-progress :text-inside="true" show-text :stroke-width="26" :percentage="item.percentage" />
                  </div>
                </div>
                <br>
                <hr>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <div v-if="showInspectCard&&parentTab==='first'" id="inspect" />
    <div v-if="inspect.length>0&&parentTab==='second'" id="construction" />
  </div>
</template>

<script>
import SimpleForm from '@/components/simple/SimpleForm'
import ArrayUtils from '@/common/js/ArrayUtils'
import * as echarts from 'echarts'
export default {
  name: 'Statistics',
  components: { SimpleForm },
  inject: ['user', 'getDevice'],
  data() {
    return {
      searchForm: { projectId: null },
      argsSearchForm: [
        {
          param: 'projectId',
          name: '项目名称',
          search: true,
          filterable: true,
          type: 'select',
          option: [],
        }
      ],
      project: {},
      inspect: [],
      device: [],
      constructionDevice: [],
      inspectData: [],
      constructData: [],
      activeName: 'total',
      constructShow: [],
      constructOption: {
        name: '施工设备类型',
        type: 'pie',
        data: null,
        radius: [0, '50%'],
        label: {
          formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ',
          backgroundColor: '#F6F8FC',
          borderColor: '#8C8D8E',
          borderWidth: 1,
          borderRadius: 4,
          rich: {
            a: {
              color: '#6E7079',
              lineHeight: 22,
              align: 'center'
            },
            hr: {
              borderColor: '#8C8D8E',
              width: '100%',
              borderWidth: 1,
              height: 0
            },
            b: {
              color: '#4C5058',
              fontSize: 14,
              fontWeight: 'bold',
              lineHeight: 33
            },
            per: {
              color: '#fff',
              backgroundColor: '#4C5058',
              padding: [3, 4],
              borderRadius: 4
            }
          }
        },
      },
      parentTab: 0,
      inspectEcharts: null,
      constructionEcharts: null,
      myMap: {},
    }
  },
  computed: {
    showInspectCard() {
      return this.inspect.length > 0 && ArrayUtils.getArrayObject(this.user().projectList, 'id', this.searchForm.projectId).type.ordinal === 0
    },
  },
  created() {
    this.myMap = new Map()
    this.getDevice()
    this.getConstructionDevice()
    this.getProjectList()
  },
  mounted() {
    // if (!this.searchForm.projectId) {
    //   this.$OneMessage.warning('该账号暂未绑定项目')
    // }
    const f = async() => {
      if (this.inspectEcharts) {
        this.inspectEcharts.dispose()
      }
      if (this.constructionEcharts) {
        this.constructionEcharts.dispose()
      }
      if (this.showInspectCard && this.parentTab === 'first') {
        this.inspectChart()
      } else if (this.inspect.length > 0 && this.parentTab === 'second') {
        this.inspectChart()
        await this.into()
        await this.showConstruction()
      }
    }
    this.$watch(() => this.searchForm.projectId, () => {
      if (this.searchForm.projectId) {
        this.activeName = 'total'
        this.search().then(f)
      }
    }, { immediate: true })
    this.$watch(() => this.parentTab, f)
  },
  methods: {
    into() {
      return new Promise((resolve) => {
        this.$get('/api/material/countByProjectId?projectId=' + this.searchForm.projectId).then(result => {
          const data = JSON.parse(result.data)
          for (let i = 0; i < data.length; i++) {
            this.myMap.set(data[i].deviceId, data[i].count)
          }
          resolve()
        })
      })
    },
    inspectChart() {
      const temp = {}
      this.inspect.forEach(item => {
        temp[item.inspectDeviceId] = temp[item.inspectDeviceId] ? temp[item.inspectDeviceId] + 1 : 1
      })
      this.inspectData = Object.keys(temp).map(item => {
        return {
          name: ArrayUtils.getArrayObject(this.device, 'id', item)?.name,
          value: temp[item],
          deviceId: item
        }
      })
      // 绘制图表
      if (this.showInspectCard && this.parentTab === 'first') {
        const myChart = echarts.init(document.getElementById('inspect'))
        this.inspectEcharts = myChart
        myChart.setOption({
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 10,
            top: 20,
            bottom: 20,
            data: this.device.map(item => item.name)
          },
          series: [
            {
              name: '总计',
              type: 'pie',
              selectedMode: 'single',
              radius: [0, '50%'],
              label: {
                position: 'inner',
                fontSize: 14
              },
              labelLine: {
                show: false
              },
              data: [
                {
                  value: this.inspect.length,
                  name: '总计'
                },
              ]
            },
            {
              name: '查勘统计',
              type: 'pie',
              radius: ['60%', '75%'],
              labelLine: {
                length: 30
              },
              label: {
                formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ',
                backgroundColor: '#deff79',
                borderColor: '#8C8D8E',
                borderWidth: 1,
                borderRadius: 4,
                rich: {
                  a: {
                    color: '#6E7079',
                    lineHeight: 22,
                    align: 'center'
                  },
                  hr: {
                    borderColor: '#8C8D8E',
                    width: '100%',
                    borderWidth: 1,
                    height: 0
                  },
                  b: {
                    color: '#4C5058',
                    fontSize: 14,
                    fontWeight: 'bold',
                    lineHeight: 33
                  },
                  per: {
                    color: '#fff',
                    backgroundColor: '#4C5058',
                    padding: [3, 4],
                    borderRadius: 4
                  }
                }
              },
              data: this.inspectData
            }
          ]
        })
        window.onresize = () => myChart.resize()
      }
    },
    showConstruction: function() {
      this.into()
      // 表格
      const constructionList = this.inspect.filter(item => item.stage)
      let tamp = [
        {
          label: '总计',
          name: 'total',
          shows: [
            {
              name: '点位数',
              param: this.inspect.length,
            },
            {
              name: '点位完成数',
              param: constructionList.length,
            },
          ],
          percentage: 0,
        },
      ]
      this.inspectData.forEach(item => {
        tamp.push({
          label: item.name,
          name: item.deviceId + '',
          shows: [
            {
              name: '点位总数',
              param: item.value,
            },
            {
              name: '点位完成数',
              param: ArrayUtils.getArrayByKeyToObject(constructionList, 'inspectDeviceId', item.deviceId)?.length,
            }],
          percentage: 0,
        })
      })
      if (this.project.type.ordinal) {
        tamp = [
          {
            label: '总计',
            name: 'total',
            shows: [
              {
                name: '设备总数',
                param: this.deviceCount,
              },
              {
                name: '施工完成数',
                param: constructionList.length,
              },
            ],
            percentage: 0,
          },
        ]
        this.inspectData.forEach(item => {
          const num = this.myMap.get(parseInt(item.deviceId))
          tamp.push({
            label: item.name,
            name: item.deviceId + '',
            shows: [
              {
                name: '设备总数',
                param: num,
              },
              {
                name: '设备完成数',
                param: ArrayUtils.getArrayByKeyToObject(constructionList, 'inspectDeviceId', item.deviceId)?.length,
              }],
            percentage: 0,
          })
        })
      }
      tamp.map(item => {
        const percentage = Math.floor((item.shows[0].param === 0 ? 0 : item.shows[1].param / item.shows[0].param * 100))
        if (percentage >= 100) {
          item.finish = true
        }
        item.percentage = percentage
      })
      this.constructShow = tamp
      const myChart = echarts.init(document.getElementById('construction'))
      this.constructionEcharts = myChart
      // 绘制图表
      const c = []
      constructionList.forEach(item => {
        const mapOb = {}
        const arrayObject = ArrayUtils.getArrayObject(c, 'constructionDeviceId', item.constructionDeviceId)
        if (arrayObject) {
          arrayObject[item.inspectDeviceId] = arrayObject[item.inspectDeviceId] + 1
          arrayObject[item.constructionDeviceId] = arrayObject[item.constructionDeviceId] + 1
        } else {
          mapOb[item.inspectDeviceId] = 1
          mapOb[item.constructionDeviceId] = 1
          mapOb.constructionDeviceId = item.constructionDeviceId
          mapOb.inspectDeviceId = item.inspectDeviceId
          c.push(mapOb)
        }
      })
      this.constructData = c.map(item => {
        return {
          name: ArrayUtils.getArrayObject(this.constructionDevice, 'id', item.constructionDeviceId)?.name,
          value: item[item.constructionDeviceId],
          deviceId: item.inspectDeviceId,
        }
      })
      this.constructOption.data = this.inspectData
      myChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c}',
        },
        series: this.constructOption,
      })
      window.onresize = () => myChart.resize()
      myChart.on('click', (params) => {
        if (this.activeName === 'total') {
          this.activeName = ArrayUtils.getArrayObject(this.device, 'name', params.name)?.id + ''
        }
      })
      this.$watch(() => this.activeName, () => {
        if (this.activeName === 'total') {
          this.constructOption.data = this.inspectData.map(item => {
            return {
              name: item.name,
              value: item.value,
            }
          })
        } else {
          // eslint-disable-next-line eqeqeq
          this.constructOption.data = this.constructData.filter(item => item.deviceId == this.activeName)
        }
        myChart.setOption({
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c}',
          },
          series: this.constructOption,
        })
      })
    },
    search() {
      this.into()
      if (!this.searchForm.projectId) {
        this.$OneMessage.warning('请选择账号绑定的项目')
        return
      }
      this.$get('/api/material/count?projectId=' + this.searchForm.projectId).then(result => { this.deviceCount = result.data })
      return this.$get('/api/project/getOne?id=' + this.searchForm.projectId).then(result => {
        this.project = result.data.project
        this.inspect = result.data.inspectList
        if (this.parentTab) {
          if (this.showInspectCard) {
            this.parentTab = 'first'
          } else if (this.inspect.length > 0) {
            this.parentTab = 'second'
          }
        }
      })
    },
    getConstructionDevice() {
      this.$get('/api/constructionDevice/getAll').then(device => {
        this.constructionDevice = device.data
      })
    },
    getProjectList() {
      const list = this.user().projectList.filter(item => {
        return item.stage.ordinal !== 2
      })
      this.argsSearchForm[0].option = list.map(item => {
        return {
          label: item.name,
          value: item.id,
        }
      })
    },
  }
}
</script>
<style  scoped lang="scss">
#inspect{
  width: 100%;
  height: calc(100vh - 200px);
  margin: 0 auto;
}
#construction{
  width: 100%;
  height: 60vh;
  margin: 0 auto;
}
.statistics{
  width: 31%;
  display: inline-block;
}
.statistics_child{
}
.statistics{
  margin-left: 2.5rem;
}
</style>
